import axios from "axios";
import axiosInstance from "../../axios/api";
import * as types from "./actionTypes";
import { beginApiCall, apiCallError } from "./apiStatusActions";
import { backend_prefix } from "../../static";

export const loginUserSuccess = (data) => {
  return { type: types.LOGIN_USER_SUCCESS, data };
};

export const loginUser = (user) => async (dispatch) => {
  dispatch(beginApiCall());
  return axios
    .post(`${backend_prefix}/api/user/token/obtain/`, {
      username: user.username,
      password: user.password,
    })
    .then((response) => {
      dispatch(
        loginUserSuccess({
          refresh: response.data.refresh,
          access: response.data.access,
        })
      );
    })
    .catch((e) => {
      dispatch(apiCallError(e));
      throw e;
    });
};

export function loadUserInfoSuccess(data) {
  return { type: types.LOAD_USER_INFO_SUCCESS, data };
}

export function loadUserInfo() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return axiosInstance
      .get(`${backend_prefix}/api/user/current/`)
      .then((response) => {
        dispatch(loadUserInfoSuccess(response.data));
      })
      .catch((e) => {
        dispatch(apiCallError(e));
        throw e;
      });
  };
}
