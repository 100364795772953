// import moment from "moment";
// export function isScheduledForWFH(workFromHomeSchedule) {
//   if (workFromHomeSchedule) {
//     switch (moment().format("dddd")) {
//       case "Monday":
//         return workFromHomeSchedule.monday;
//       case "Tuesday":
//         return workFromHomeSchedule.tuesday;
//       case "Wednesday":
//         return workFromHomeSchedule.wednesday;
//       case "Thursday":
//         return workFromHomeSchedule.thursday;
//       case "Friday":
//         return workFromHomeSchedule.friday;
//       default:
//         return false;
//     }
//   }
//   return false;
// }

import moment from "moment";
export function isScheduled(schedule) {
  if (schedule) {
    switch (moment().format("dddd")) {
      case "Monday":
        return schedule.monday;
      case "Tuesday":
        return schedule.tuesday;
      case "Wednesday":
        return schedule.wednesday;
      case "Thursday":
        return schedule.thursday;
      case "Friday":
        return schedule.friday;
      default:
        return false;
    }
  }
  return false;
}
