import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function userReducer(state = initialState.link, action) {
  const { data } = action;
  switch (action.type) {
    case types.SET_LINK:
      return data;

    default:
      return state;
  }
}
