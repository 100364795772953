import React from "react";
import { Link, Typography } from "@mui/material";

const Footer = ({ ...props }) => {
  return (
    <>
      <Typography variant="body2" align="center" {...props} sx={{ pt: 3 }}>
        {"Copyright © "}
        <Link color="inherit" href="https://dotr.gov.ph/" target="_blank">
          Department of Transportation
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
      <Typography variant="body2" align="center" sx={{ pb: 3 }}>
        {"Developed by: Management Information Service"}
      </Typography>
    </>
  );
};
export default Footer;
