import * as React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import Theme from "../Template/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

const SideBarItems = ({ links, open, ui }) => {
  return (
    <>
      <List>
        {links?.map((link) => {
          return (
            <ListItem disablePadding sx={{ display: "block" }} key={link.title}>
              <ListItemButton
                selected={ui.active_nav === link.title}
                key={link.title}
                component={RouterLink}
                to={link.url}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FontAwesomeIcon
                    icon={link.icon}
                    fixedWidth
                    color={Theme.palette.text.primary}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={link.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};
function mapStateToProps(state) {
  return {
    ui: state.ui,
  };
}

export default connect(mapStateToProps)(SideBarItems);
