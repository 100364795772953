import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Button, Grid, setRef, Typography } from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFolderOpen, faSync } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Element/Loading";
import QrcodecDetails from "./QrcodecDetails";
import LoadQrcodecForm from "./LoadQrcodecForm";
import * as uiActions from "../../store/actions/uiActions";



// import {
//   Alert,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
// } from "@mui/material";
// import axiosInstance from "../../axios/api";
// import { backend_prefix } from "../../static";
// import { request_timeout } from "../../static";
const Qrcodec = ({ user, actions }) => {
  const Item = useOutletContext();
  const [isQrcodecLoading, setIsQrcodecLoading] = useState(false);
  const [qrcodec, setQrcodec] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    actions.setTitle("QRCODE");
    actions.setActiveNav("QRCODE");
    actions.setBreadcrumbs([
      { link: "/", title: "HOME" },
      { title: "QRCODE" },
    ]);
  }, []);
  return (
    <Grid container spacing={2} elevation={3}>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          {user?.info?.access?.filter((access_title) => {
            if (access_title === "qrcodec.sync_qrcodecperiod") {
              return access_title;
            }
          }).length > 0 && (
            <Grid item xs={12} md={12}>
              <Item>
              </Item>
            </Grid>
          )}
          {/* end of sync */}
          <Grid item xs={12} md={12}>
            <Item>
              <LoadQrcodecForm
                setQrcodec={setQrcodec}
                setIsQrcodecLoading={setIsQrcodecLoading}
                setActiveTab={setActiveTab}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Item>
          </Grid>
        </Grid>
      </Grid>
      {activeTab === "Qrcodec" &&
        (qrcodec?.qrcodec_period || isQrcodecLoading) && (
          <>
            <Grid item xs={12} md={8}>
              <Item>
                {isQrcodecLoading ? (
                  <Loading />
                ) : (
                  <>
                    {/* <Typography component="h3" variant="h5">
                      <b>QRCODE:</b>
                      {qrcodec.qrcodec_period &&
                        ` ${qrcodec.qrcodec_period.title} `}
                    </Typography> */}

                    {qrcodec?.qrcodec_employee?.map((employee) => {
                      return (
                        <QrcodecDetails
                          key={"qrcodec" + employee.employee_number}
                          period={qrcodec?.qrcodec_period}
                          employee={employee}
                        />
                      );
                    })}
                  </>
                )}
              </Item>
            </Grid>
          </>
        )}
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setTitle: bindActionCreators(uiActions.setTitle, dispatch),
      setActiveNav: bindActionCreators(uiActions.setActiveNav, dispatch),
      setBreadcrumbs: bindActionCreators(uiActions.setBreadcrumbs, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Qrcodec);
