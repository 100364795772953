import { Controller } from "react-hook-form";
import { TextField as MuiTextField } from "@mui/material";
const TextField = ({
  control,
  name,
  type,
  label,
  required,
  autoComplete,
  autofocus,
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { name, onChange, value } }) => (
        <MuiTextField
          name={name}
          id={name}
          type={type}
          label={label}
          value={value}
          required={required}
          autoComplete={autoComplete}
          autoFocus={autofocus}
          onChange={onChange}
          margin="normal"
          fullWidth
        />
      )}
    />
  );
};
export default TextField;
