import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";
import moment from "moment";
import { bindActionCreators } from "redux";
import { Box, Button, Grid, Tabs,Tab, Typography } from "@mui/material";
import Loading from "../../components/Element/Loading";
import * as uiActions from "../../store/actions/uiActions";
import { Alert } from "@mui/material";
import axiosInstance from "../../axios/api";
import { backend_prefix } from "../../static";
import { isScheduled } from "../../utils/timekeeping";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


//const Timekeeping = ({ user, actions }) => {
//  const Item = useOutletContext();
//  const [isWorkFromHomeScheduleLoading, setIsWorkFromHomeScheduleLoading] =  useState(false);
//  const [workFromHomeSchedule, setWorkFromHomeSchedule] = useState(null);
//  const [isOfficialBusinessScheduleLoading, setIsOfficialBusinessScheduleLoading] = useState(false);
//  const [officialBusinessSchedule, setOfficialBusinessSchedule] = useState(null);
//  const [activeTab, setActiveTab] = useState(0);
//  const [isProcessing, setIsProcessing] = useState(false);
//  const [processingMessage, setProcessingMessage] = useState({ status: null, message: "",});
//  const [isDTRLoading, setIsDTRLoading] = useState(false);
//  const [dtr, setDTR] = useState(false);
//  const [isAllowedToWFH, setIsAllowedToWFH] = useState(false);
//  const [isAllowedToOB, setIsAllowedToOB] = useState(false);
//  useEffect(() => {
//    actions.setTitle("TIMEKEEPING");
//    actions.setActiveNav("TIMEKEEPING");
//    actions.setBreadcrumbs([
//      { link: "/", title: "HOME" },
//      { title: "TIMEKEEPING" },
//    ]);
//    loadWorkFromHomeSchedule();
//    loadOfficialBusinessSchedule();
//    loadDTR();
//  }, []);

const Timekeeping = ({ user, actions }) => {
  const Item = useOutletContext();
  const [isWorkFromHomeScheduleLoading, setIsWorkFromHomeScheduleLoading] =
    useState(false);
  const [workFromHomeSchedule, setWorkFromHomeSchedule] = useState(null);
  const [isOfficialBusinessScheduleLoading, setIsOfficialBusinessScheduleLoading] =
      useState(false);
  const [officialBusinessSchedule, setOfficialBusinessSchedule] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const [processingMessage, setProcessingMessage] = useState({
    status: null,
    message: "",
  });
  const [isDTRLoading, setIsDTRLoading] = useState(false);
  const [dtr, setDTR] = useState(false);
  const [isAllowedToWFH, setIsAllowedToWFH] = useState(false);
  const [isAllowedToOB, setIsAllowedToOB] = useState(false);
  useEffect(() => {
    actions.setTitle("TIMEKEEPING");
    actions.setActiveNav("TIMEKEEPING");
    actions.setBreadcrumbs([
      { link: "/", title: "HOME" },
      { title: "TIMEKEEPING" },
    ]);
    loadWorkFromHomeSchedule();
    loadOfficialBusinessSchedule();
    loadDTR();
  }, []);

  const loadWorkFromHomeSchedule = async () => {
    await setIsWorkFromHomeScheduleLoading(true);
    await axiosInstance
      .get(`${backend_prefix}/api/timekeeping/schedule/get/wfh/current`)
      .then(async (response) => {
	console.log(response)
        await setWorkFromHomeSchedule(response.data);
        await setIsWorkFromHomeScheduleLoading(false);
      })
      .catch((e) => {
        setIsWorkFromHomeScheduleLoading(false);
	console.log("error")
        throw e;
      });
    await setIsWorkFromHomeScheduleLoading(false);
  };
  const loadOfficialBusinessSchedule = async () => {
    await setIsOfficialBusinessScheduleLoading(true);
    await axiosInstance
      .get(`${backend_prefix}/api/timekeeping/schedule/get/ob/current`)
      .then(async (response) => {
        await setOfficialBusinessSchedule(response.data);
        await setIsOfficialBusinessScheduleLoading(false);
      })
      .catch((e) => {
        setIsOfficialBusinessScheduleLoading(false);
        throw e;
      });
    await setIsOfficialBusinessScheduleLoading(false);
  };
  useEffect(() => {
    setIsAllowedToWFH(isScheduled(workFromHomeSchedule));
    setIsAllowedToOB(isScheduled(officialBusinessSchedule));
  });
  const processTime = async (e) => {
    await setDTR(null);
    await setIsProcessing(true);
    await axiosInstance
      .post(`${backend_prefix}/api/timekeeping/save`, {
        type: e.type,
        request_type: e.requestType,
      })
      .then(async (response) => {
        await loadDTR();
        await setProcessingMessage({
          status: "success",
          message: `${e.requestType} has been successfully updated.`,
        });
        await setIsProcessing(false);
      })
      .catch((e) => {
        setProcessingMessage({
          status: "error",
          message: `Something went wrong. Please contact the administrator.`,
        });

        throw e;
      });
    await setIsProcessing(false);
  };
  const loadDTR = async () => {
    await setIsDTRLoading(true);
    await setDTR({ dtr: { time_in: "asdasd" } });
    await setIsDTRLoading(false);

    await setIsDTRLoading(false);
  };
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    console.log(newValue)
  };
  return (
    <Grid container spacing={2} elevation={3}>
      <Grid item xs={12} md={4}>
        <Grid container w={100}>

      <br />

      <Grid item xs={12} md={12}>
            <Item>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="WFH" {...a11yProps(0)} />
          <Tab label="OB" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={activeTab} index={0}>
              {!isWorkFromHomeScheduleLoading && !isScheduled(workFromHomeSchedule) && (
                <Alert severity="error">
                  You are not authorized to use the timekeeping for WFH.
                </Alert>
              )}
              {processingMessage.status && (
                <Alert
                  sx={{ p: 3 }}
                  severity={processingMessage.status}
                  onClose={() => {
                    setProcessingMessage({
                      status: null,
                      message: "",
                    });
                  }}
                >
                  {processingMessage.message}
                </Alert>
              )}
              {isWorkFromHomeScheduleLoading || isProcessing ? (
                <Loading />
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item p={1} xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={
                        !isAllowedToWFH ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(12, "hours") <= moment()
                      }
                      onClick={(e) => processTime({type:"WFH",requestType:"Time In"})}
                    >
                      Time In
                    </Button>
                  </Grid>
                  {/* <Grid item p={1} xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={
                        !isAllowedToSave ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(12, "hours") > moment() ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(13, "hours") < moment()
                      }
                      onClick={(e) => processTime("Lunch Out")}
                    >
                      Lunch Out
                    </Button>
                  </Grid>
                  <Grid item p={1} xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={
                        !isAllowedToSave ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(12, "hours") > moment() ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(13, "hours") < moment()
                      }
                      onClick={(e) => processTime("Lunch In")}
                    >
                      Lunch In
                    </Button>
                  </Grid> */}

                  <Grid item p={1} xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={
                        !isAllowedToWFH ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(13, "hours") > moment()
                      }

                      onClick={(e) => processTime({type:"WFH",requestType:"Time Out"})}
                    >
                      Time Out
                    </Button>
                  </Grid>
                </Grid>
              )}
      </CustomTabPanel>
      <CustomTabPanel value={activeTab} index={1}>
              {!isOfficialBusinessScheduleLoading && !isScheduled(officialBusinessSchedule) && (
                <Alert severity="error">
                  You are not authorized to use the timekeeping for OB.
                </Alert>
              )}
              {processingMessage.status && (
                <Alert
                  sx={{ p: 3 }}
                  severity={processingMessage.status}
                  onClose={() => {
                    setProcessingMessage({
                      status: null,
                      message: "",
                    });
                  }}
                >
                  {processingMessage.message}
                </Alert>
              )}
              {isWorkFromHomeScheduleLoading || isProcessing ? (
                <Loading />
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item p={1} xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={
                        !isAllowedToOB ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(12, "hours") <= moment()
                      }
                      onClick={(e) => processTime({type:"OB",requestType:"Time In"})}
                    >
                      Time In
                    </Button>
                  </Grid>
                  {/* <Grid item p={1} xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={
                        !isAllowedToSave ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(12, "hours") > moment() ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(13, "hours") < moment()
                      }
                      onClick={(e) => processTime("Lunch Out")}
                    >
                      Lunch Out
                    </Button>
                  </Grid>
                  <Grid item p={1} xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={
                        !isAllowedToSave ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(12, "hours") > moment() ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(13, "hours") < moment()
                      }
                      onClick={(e) => processTime("Lunch In")}
                    >
                      Lunch In
                    </Button>
                  </Grid> */}

                  <Grid item p={1} xs={12} sm={12} md={12}>
                    <Button
                      variant="contained"
                      disabled={
                        !isAllowedToOB ||
                        moment(
                          moment().format("MMMM Do YYYY"),
                          "MMMM Do YYYY,"
                        ).add(13, "hours") > moment()
                      }
                      onClick={(e) => processTime({type:"OB",requestType:"Time Out"})}
                    >
                      Time Out
                    </Button>
                  </Grid>
                </Grid>
              )}
      </CustomTabPanel>
            </Item>
                      </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={8}>
        <Item>
          <Typography component="h3" variant="h5" sx={{ pb: 3 }}>
            DTR
          </Typography>
          <Typography component="p" variant="p" sx={{ pb: 3 }}>
            This panel is under construction, you may view your DTR @
            https://hris.dotr.gov.ph
          </Typography>
        </Item>
      </Grid>
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setTitle: bindActionCreators(uiActions.setTitle, dispatch),
      setActiveNav: bindActionCreators(uiActions.setActiveNav, dispatch),
      setBreadcrumbs: bindActionCreators(uiActions.setBreadcrumbs, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Timekeeping);
