import React, { useState } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";

import DetailsPerCategory from "./DetailsPerCategory";
import { backend_prefix } from "../../static";
import CurrencyText from "../../components/Element/CurrencyText";

const PayslipDetails = ({ period, employee }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <>
      <Divider style={{ pt: 5, pb: 5, borderColor: "black" }} />

      <Typography component="h4" variant="h6">
        EMPLOYEE INFORMATION
      </Typography>
      <Typography component="h4" variant="h6">
        Employee Number:
        {` ${employee.employee_number}`}
      </Typography>
      <Typography component="h4" variant="h6">
        Employee Name:
        {` ${employee.employee_name}`}
      </Typography>
      <Typography component="h4" variant="h6">
        Organization Unit:
        {` ${employee.organization_name}`}
      </Typography>
      <Button
        component={"a"}
        variant="contained"
        //href={`${backend_prefix}/api/payroll/${period.id}/${employee.employee_number}/print`}
        href={`${backend_prefix}/api/payroll/${period.id}/${employee.employee_number}/print`}
        target={"_blank"}
        download={`Payslip - ${employee.employee_name}-${period.date_to}-${period.date_from}.pdf`}
      >
        Print/Download
      </Button>
      <Divider style={{ margin: 10 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography component="h4" variant="h6" sx={{ mt: 2 }}>
            BASIC PAY
          </Typography>
          <DetailsPerCategory details={employee.details} filter="PAY" />
          <Typography component="h4" variant="h6" sx={{ mt: 2 }}>
            OTHER EARNINGS
          </Typography>
          <DetailsPerCategory details={employee.details} filter="OER" />
          <Typography component="h4" variant="h6" sx={{ mt: 2 }}>
            CONTRIBUTION
          </Typography>
          <DetailsPerCategory details={employee.details} filter="TAX" />
          <DetailsPerCategory details={employee.details} filter="CON" />
          <Typography component="h4" variant="h6" sx={{ mt: 2 }}>
            OTHER DETAILS
          </Typography>
          <DetailsPerCategory details={employee.details} filter="LVE" />
          <DetailsPerCategory details={employee.details} filter="ODN" />
          <DetailsPerCategory details={employee.details} filter="LON" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography component="h4" variant="h6" sx={{ mt: 2 }}>
            GROSS PAY: <CurrencyText value={`${employee?.gross_pay}`} />
          </Typography>
          <Typography component="h4" variant="h6" sx={{ mt: 2 }}>
            DEDUCTIONS: <CurrencyText value={`${employee?.deduction}`} />
          </Typography>
          <Typography component="h4" variant="h6" sx={{ mt: 2 }}>
            NET PAY: <CurrencyText value={`${employee?.net_pay}`} />
          </Typography>
          <Typography component="h4" variant="h6" sx={{ mt: 2 }}>
            FIRST HALF: <CurrencyText value={`${employee?.first_half}`} />
          </Typography>
          <Typography component="h4" variant="h6" sx={{ mt: 2 }}>
            SECOND HALF: <CurrencyText value={`${employee?.second_half}`} />
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default PayslipDetails;
