import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Alert, Collapse, IconButton } from "@mui/material";
const ErrorMessage = ({ message, setMessage, size }) => {
  return (
    <Collapse in={message?.content ? true : false}>
      <Alert
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size={size}
            onClick={() => {
              setMessage({ type: "", content: "" });
            }}
          >
            <FontAwesomeIcon icon={faClose} fixedWidth />
          </IconButton>
        }
        sx={{ mb: 2 }}
        severity={message?.type ? message.type : "error"}
      >
        {message?.content}
      </Alert>
    </Collapse>
  );
};
export default ErrorMessage;
