import * as types from "../actions/actionTypes";
import axiosInstance from "../../axios/api";
import initialState from "./initialState";

export default function userReducer(state = initialState.user, action) {
  const { data } = action;
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      axiosInstance.defaults.headers["Authorization"] = "Bearer " + data.access;
      localStorage.setItem("access_token", data.access);
      localStorage.setItem("refresh_token", data.refresh);
      return state;
    case types.LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        info: { ...state.info, ...data },
      };

    default:
      return state;
  }
}
