import { combineReducers } from "redux";
import apiCallsInProgress from "./apiStatusReducer";
import user from "./userReducer";
import ui from "./uiReducers";
import link from "./linkReducers";
const rootReducer = combineReducers({
  apiCallsInProgress,
  ui,
  user,
  link,
});

export default rootReducer;
