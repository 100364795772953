import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../axios/api";
import { backend_prefix } from "../../static";
import Loading from "../../components/Element/Loading";

const LoadPayslipForm = ({
  setIsPayrollLoading,
  setPayroll,
  setActiveTab,
  user,
  refresh,
  setRefresh,
}) => {
  const [isPayrollPeriodsLoading, setIsPayrollPeriodsLoading] = useState(false);
  const [payrollPeriods, setPayrollPeriods] = useState(null);
  const [payrollPeriod, setPayrollPeriod] = useState("");

  const loadPayrollPeriods = async () => {
    await setPayroll(null);
    await setIsPayrollPeriodsLoading(true);
    console.log(user);
    axiosInstance
      .get(
        user?.info?.access?.filter((access_title) => {
          if (access_title === "payroll.sync_payrollperiod") {
            return access_title;
          }
        }).length > 0
          ? `${backend_prefix}/api/payroll/periods`
          : `${backend_prefix}/api/payroll/periods/current`
      )
      .then((response) => {
        setPayrollPeriods(response.data);
      })
      .catch((e) => {
        throw e;
      });

    await setIsPayrollPeriodsLoading(false);
  };

  const onChangePayrollPeriod = (e) => {
    setPayrollPeriod(e.target.value);
  };
  const loadPayslips = async (e) => {
    await setPayroll(null);
    setActiveTab("Payslip");
    await setIsPayrollLoading(true);
    await axiosInstance
      .get(`${backend_prefix}/api/payroll/${payrollPeriod}`)
      .then((response) => {
        setPayroll(response.data);
        setIsPayrollLoading(false);
      })
      .catch((e) => {
        throw e;
      });
    await setIsPayrollLoading(false);
  };
  const loadPayslip = async (e) => {
    await setPayroll(null);
    setActiveTab("Payslip");
    await setIsPayrollLoading(true);
    await axiosInstance
      .get(`${backend_prefix}/api/payroll/${payrollPeriod}/current`)
      .then((response) => {
        setPayroll(response.data);
        setIsPayrollLoading(false);
      })
      .catch((e) => {
        throw e;
      });
    await setIsPayrollLoading(false);
  };

  useEffect(() => {
    loadPayrollPeriods();
  }, [user]);
  useEffect(() => {
    if (refresh) {
      loadPayrollPeriods();
    }
    setRefresh(false);
  }, [refresh]);
  return (
    <>
      <Typography component="h3" variant="h5" sx={{ pb: 3 }}>
        View Payslip
      </Typography>
      {isPayrollPeriodsLoading ? (
        <Loading />
      ) : (
        <>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">
              Payroll Period:
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={payrollPeriod}
              label="Payroll Period"
              onChange={onChangePayrollPeriod}
            >
              <MenuItem value="">
                <em>&nbsp;</em>
              </MenuItem>
              {payrollPeriods?.map((payrollPeriod) => {
                return (
                  <MenuItem
                    key={"payrollPeriod" + payrollPeriod.id}
                    value={`${payrollPeriod.id}`}
                  >
                    {payrollPeriod.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{ mr: 2, mr: 2, mb: 2, mt: 2 }}
            onClick={loadPayslip}
            disabled={payrollPeriod ? false : true}
            startIcon={
              <FontAwesomeIcon icon={faEye} fixedWidth color="inherit" />
            }
          >
            View
          </Button>
          {user?.info?.access?.filter((access_title) => {
            if (access_title === "payroll.view_payrollperiod") {
              return access_title;
            }
          }).length > 0 && (
            <Button
              variant="contained"
              sx={{ mr: 2, mr: 2, mb: 2, mt: 2 }}
              disabled={payrollPeriod ? false : true}
              onClick={loadPayslips}
              startIcon={
                <FontAwesomeIcon
                  icon={faFolderOpen}
                  fixedWidth
                  color="inherit"
                />
              }
            >
              View All
            </Button>
          )}
        </>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(LoadPayslipForm);
