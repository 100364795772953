const initialState = {
  apiCallsInProgress: 0,
  ui: {
    title: "",
    active_nav: "",
    breadcrumbs: null,
  },
  user: {
    info: {
      email: "",
      username: "",
      first_name: "",
      last_name: "",
      id_number: "",
      is_payroll_sync: false,
      is_superuser: false,
    },
  },
  link: "",
};
export default initialState;
