import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../axios/api";
import { backend_prefix } from "../../static";
import Loading from "../../components/Element/Loading";
import { setActiveNav } from "../../store/actions/uiActions";

const LoadNoticeForm = ({
  setIsNoticeWithContentsLoading,
  setNoticeWithContents,
  setActiveTab,
  user,
}) => {
  const [isNoticesLoading, setIsNoticesLoading] = useState(false);
  const [notices, setNotices] = useState(null);
  const [notice, setNotice] = useState("");

  const loadNotices = async () => {
    await setNotice("");
    await setIsNoticesLoading(true);
    axiosInstance
      .get(`${backend_prefix}/api/notice/list/current`)
      .then((response) => {
        setNotices(response.data);
      })
      .catch((e) => {
        throw e;
      });

    await setIsNoticesLoading(false);
  };

  const onChangeNotice = (e) => {
    setNotice(e.target.value);
  };
  const loadNoticeWithContent = async (e) => {
    await setNoticeWithContents(null);
    await setActiveTab("NoticeWithContents");
    await setIsNoticeWithContentsLoading(true);
    await axiosInstance
      .get(`${backend_prefix}/api/notice/${notice}/current`)
      .then((response) => {
        setNoticeWithContents(response.data);
        setIsNoticeWithContentsLoading(false);
      })
      .catch((e) => {
        throw e;
      });
    await setIsNoticeWithContentsLoading(false);
  };

  useEffect(() => {
    loadNotices();
  }, []);
  return (
    <>
      <Typography component="h3" variant="h5" sx={{ pb: 3 }}>
        View Notice
      </Typography>
      {isNoticesLoading ? (
        <Loading />
      ) : (
        <>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">
              Notice:
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={notice}
              label="Notices"
              onChange={onChangeNotice}
            >
              <MenuItem value="">
                <em>&nbsp;</em>
              </MenuItem>
              {notices?.map((notice) => {
                return (
                  <MenuItem key={"notice" + notice.id} value={`${notice.id}`}>
                    {notice.title}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{ mr: 2, mr: 2, mb: 2, mt: 2 }}
            onClick={loadNoticeWithContent}
            disabled={notice ? false : true}
            startIcon={
              <FontAwesomeIcon icon={faEye} fixedWidth color="inherit" />
            }
          >
            View
          </Button>
        </>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(LoadNoticeForm);
