import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Button, Grid, setRef, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen, faSync } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Element/Loading";
import PayslipDetails from "./PayslipDetails";
import LoadPayslipForm from "./LoadPayslipForm";
import * as uiActions from "../../store/actions/uiActions";

import {
  Alert,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axiosInstance from "../../axios/api";
import { backend_prefix } from "../../static";
import { request_timeout } from "../../static";
const Payslip = ({ user, actions }) => {
  const Item = useOutletContext();
  const [isPayrollLoading, setIsPayrollLoading] = useState(false);
  const [payroll, setPayroll] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [isOraclePayrollPeriodsLoading, setIsOraclePayrollPeriodsLoading] =
    useState(false);
  const [oraclePayrollPeriods, setOraclePayrollPeriods] = useState(null);
  const [oraclePayrollPeriod, setOraclePayrollPeriod] = useState("");
  const [oracleSyncMessage, setOracleSyncMessage] = useState({
    status: null,
    message: "",
  });

  const onChangeOraclePayrollPeriod = (e) => {
    setOraclePayrollPeriod(e.target.value);
  };
  const syncOraclePayroll = async (e) => {
    await setPayroll(null);
    await setIsOraclePayrollPeriodsLoading(true);
    await axiosInstance
      .post(
        `${backend_prefix}/api/payroll/oracle_payroll/sync`,
        {
          payroll_period: oraclePayrollPeriod,
        },
        { timeout: request_timeout }
      )
      .then((response) => {
        setPayroll(null);
        setIsOraclePayrollPeriodsLoading(false);
        setOracleSyncMessage({
          status: "success",
          message: `${oraclePayrollPeriod} has been successfully sync.`,
        });
        setRefresh(true);
        setIsOraclePayrollPeriodsLoading(false);
      })
      .catch((e) => {
        setOracleSyncMessage({
          status: "error",
          message: `Something went wrong. Please contact the administrator.`,
        });
        throw e;
      });
    await setIsOraclePayrollPeriodsLoading(false);
  };
  const loadOraclePayrollPeriods = async () => {
    await setIsOraclePayrollPeriodsLoading(true);
    user?.info?.access?.filter((access_title) => {
      if (access_title === "payroll.sync_payrollperiod") {
        return access_title;
      }
    }).length > 0 &&
      (await axiosInstance
        .get(`${backend_prefix}/api/payroll/oracle_payroll/periods`)
        .then(async (response) => {
          await setOraclePayrollPeriods(response.data);
          await setIsOraclePayrollPeriodsLoading(false);
        })
        .catch((e) => {
          throw e;
        }));
    await setIsOraclePayrollPeriodsLoading(false);
  };
  useEffect(() => {
    actions.setTitle("PAYSLIP");
    actions.setActiveNav("PAYSLIP");
    actions.setBreadcrumbs([
      { link: "/", title: "HOME" },
      { title: "PAYSLIP" },
    ]);
    loadOraclePayrollPeriods();
  }, []);
  return (
    <Grid container spacing={2} elevation={3}>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          {user?.info?.access?.filter((access_title) => {
            if (access_title === "payroll.sync_payrollperiod") {
              return access_title;
            }
          }).length > 0 && (
            <Grid item xs={12} md={12}>
              <Item>
                <Typography component="h3" variant="h5" sx={{ pb: 3 }}>
                  Sync Payroll
                </Typography>
                {oracleSyncMessage.status && (
                  <Alert
                    sx={{ p: 3 }}
                    severity={oracleSyncMessage.status}
                    onClose={() => {
                      setOracleSyncMessage({
                        status: null,
                        message: "",
                      });
                    }}
                  >
                    {oracleSyncMessage.message}
                  </Alert>
                )}
                {isOraclePayrollPeriodsLoading ? (
                  <Loading />
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item p={1} xs={12} sm={12} md={12}>
                      <FormControl sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-helper-label">
                          Payroll Period
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          value={oraclePayrollPeriod}
                          label="Payroll Period"
                          onChange={onChangeOraclePayrollPeriod}
                        >
                          <MenuItem value="">
                            <em>&nbsp;</em>
                          </MenuItem>
                          {oraclePayrollPeriods?.map((oraclePayrollPeriod) => {
                            return (
                              <MenuItem
                                key={
                                  "payrollPeriod" + oraclePayrollPeriod.title
                                }
                                value={oraclePayrollPeriod.title}
                              >
                                {oraclePayrollPeriod.title}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>{" "}
                    <Grid
                      item
                      p={1}
                      xs={12}
                      sm={12}
                      md={12}
                      alignItems={"center"}
                    >
                      <Button
                        variant="contained"
                        disabled={oraclePayrollPeriod ? false : true}
                        onClick={syncOraclePayroll}
                        startIcon={
                          <FontAwesomeIcon
                            icon={faSync}
                            fixedWidth
                            color="inherit"
                          />
                        }
                      >
                        Sync
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Item>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Item>
              <LoadPayslipForm
                setPayroll={setPayroll}
                setIsPayrollLoading={setIsPayrollLoading}
                setActiveTab={setActiveTab}
                refresh={refresh}
                setRefresh={setRefresh}
              />
            </Item>
          </Grid>
        </Grid>
      </Grid>
      {activeTab === "Payslip" &&
        (payroll?.payroll_period || isPayrollLoading) && (
          <>
            <Grid item xs={12} md={8}>
              <Item>
                {isPayrollLoading ? (
                  <Loading />
                ) : (
                  <>
                    <Typography component="h3" variant="h5">
                      PAY PERIOD:
                      {payroll.payroll_period &&
                        ` ${payroll.payroll_period.date_from} - ${payroll.payroll_period.date_to}`}
                    </Typography>

                    {payroll?.payroll_employee?.map((employee) => {
                      return (
                        <PayslipDetails
                          key={"payslip" + employee.employee_number}
                          period={payroll?.payroll_period}
                          employee={employee}
                        />
                      );
                    })}
                  </>
                )}
              </Item>
            </Grid>
          </>
        )}
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setTitle: bindActionCreators(uiActions.setTitle, dispatch),
      setActiveNav: bindActionCreators(uiActions.setActiveNav, dispatch),
      setBreadcrumbs: bindActionCreators(uiActions.setBreadcrumbs, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Payslip);
