import { Button, CircularProgress } from "@mui/material";
const SubmitButton = ({ text, isLoading }) => {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      disabled={isLoading}
    >
      {text}
      {isLoading && " " && (
        <CircularProgress color="inherit" size={15} sx={{ ml: 2 }} />
      )}
    </Button>
  );
};
export default SubmitButton;
