import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../axios/api";
import { backend_prefix } from "../../static";
import Loading from "../../components/Element/Loading";

const LoadQrcodecForm = ({
  setIsQrcodecLoading,
  setQrcodec,
  setActiveTab,
  user,
  refresh,
  setRefresh,
}) => {
  const [isQrcodecPeriodsLoading, setIsQrcodecPeriodsLoading] = useState(false);
  const [qrcodecPeriods, setQrcodecPeriods] = useState(null);
  const [qrcodecPeriod, setQrcodecPeriod] = useState("");

  const loadQrcodecPeriods = async () => {
    await setQrcodec(null);
    await setIsQrcodecPeriodsLoading(true);
    console.log(user);
    axiosInstance
      .get(
        user?.info?.access?.filter((access_title) => {
          if (access_title === "qrcodec.sync_qrcodecperiod") {
            return access_title;
          }
        }).length > 0
          ? `${backend_prefix}/api/qrcodec/periods`
          : `${backend_prefix}/api/qrcodec/periods/current`
      )
      .then((response) => {
        setQrcodecPeriods(response.data);
      })
      .catch((e) => {
        throw e;
      });

    await setIsQrcodecPeriodsLoading(false);
  };

  const loadQrcodecs = async (e) => {
    await setQrcodec(null);
    setActiveTab("Qrcodec");
    await setIsQrcodecLoading(true);
    await axiosInstance
      .get(`${backend_prefix}/api/qrcodec/${qrcodecPeriod}`)
      .then((response) => {
        setQrcodec(response.data);
        setIsQrcodecLoading(false);
      })
      .catch((e) => {
        throw e;
      });
    await setIsQrcodecLoading(false);
  };

  const loadQrcodec = async (e) => {
    await setQrcodec(null);
    setActiveTab("Qrcodec");
    await setIsQrcodecLoading(true);
    await axiosInstance
      .get(`${backend_prefix}/api/qrcodec/${qrcodecPeriod}/current`)
      .then((response) => {
        setQrcodec(response.data);
        setIsQrcodecLoading(false);
      })
      .catch((e) => {
        throw e;
      });
    await setIsQrcodecLoading(false);
  };

  useEffect(() => {
    loadQrcodecPeriods();
  }, [user]);
  useEffect(() => {
    if (refresh) {
      loadQrcodecPeriods();
    }
    setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    // Load the first value when the component mounts
    if (qrcodecPeriods && qrcodecPeriods.length > 0) {
      setQrcodecPeriod(`${qrcodecPeriods[0].id}`);
    }
  }, [qrcodecPeriods]);

  return (
    <>
      <Typography component="h3" variant="h5" sx={{ pb: 3 }}>
        View QRCODE
      </Typography>
      {isQrcodecPeriodsLoading ? (
        <Loading />
      ) : (
        <>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label">
              {/* QR Code: */}
            </InputLabel>
            <TextField
              id="qrcodecPeriod"
              label="Qr Code"
              value={qrcodecPeriods && qrcodecPeriods.length > 0 ? qrcodecPeriods[0].title : ''}
              InputProps={{
                readOnly: true,
              }}
              hidden={true}
            />
          </FormControl>

          <Button
            variant="contained"
            sx={{ mr: 2, mr: 2, mb: 2, mt: 2 }}
            onClick={loadQrcodec}
            disabled={qrcodecPeriod ? false : true}
            startIcon={
              <FontAwesomeIcon icon={faEye} fixedWidth color="inherit" />
            }
          >
            View
          </Button>
          {user?.info?.access?.filter((access_title) => {
            if (access_title === "qrcodec.view_qrcodecperiod") {
              return access_title;
            }
          }).length > 0 && (
            <Button
              variant="contained"
              sx={{ mr: 2, mr: 2, mb: 2, mt: 2 }}
              disabled={qrcodecPeriod ? true : true}
              onClick={loadQrcodecs}
              startIcon={
                <FontAwesomeIcon
                  icon={faFolderOpen}
                  fixedWidth
                  color="inherit"
                />
              }
            >
              View All
            </Button>
          )}
        </>
      )}
    </>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(LoadQrcodecForm);
