import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ThemeProvider } from "@mui/material/styles";
import {
  Avatar,
  Box,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import Theme from "../../components/Template/Theme";
import Footer from "../../components/Base/Footer";
import logo from "../../assets/logo.png";
import * as userActions from "../../store/actions/userActions";
import * as linkActions from "../../store/actions/linkActions";
import ErrorMessage from "../../components/Element/ErrorMessage";
import TextField from "../../components/Element/TextField";
import SubmitButton from "../../components/Element/SubmitButton";

const Signin = ({ user, link, loading, actions }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [nextLink, setNextLink] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  useEffect(() => {
    if (link) {
      setNextLink(link);
      actions.setLink("");
    }
  }, []);
  const { handleSubmit, control } = useForm({
    defaultValues: { username: "", password: "" },
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  useEffect(() => {
    if (
      localStorage.getItem("refresh_token") &&
      localStorage.getItem("access_token")
    ) {
      actions
        .loadUserInfo("current")
        .then(() => {
          navigate(nextLink && nextLink !== location.pathname ? nextLink : "/", {
            replace: true,
          });
        })
        .catch((e) => {
          localStorage.clear();
          setErrorMessage({
            type: "error",
            content: "Token has expired. Please sign in again.",
          });
        });
    }
  });

  const onSubmit = async (data) => {
    await setIsLoggingIn(true);
    await actions
      .loginUser({ username: data.username, password: data.password })
      .catch((error) => {
        setErrorMessage({
          type: "error",
          content:
            error.response?.status === 401
              ? "Invalid Username / Password"
              : "Something went wrong. Contact MIS.",
        });
        setIsLoggingIn(false);
        throw error;
      });

    await setIsLoggingIn(false);
  };
  // const handleLoginRegisterClick = (e) => {

  // };

  return (
    <ThemeProvider theme={Theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage: "url(/bg.png)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[50]
                : theme.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              alt="DOTr Logo"
              src={logo}
              sx={{ width: 150, height: 150, m: 3 }}
            />
            <Typography component="h1" variant="h4">
              DOTr Portal
            </Typography>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <ErrorMessage
              message={errorMessage}
              setMessage={setErrorMessage}
              size={"small"}
            />

            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              sx={{ mt: 1 }}
            >
              <TextField
                name={"username"}
                control={control}
                label={"Username"}
                type={"text"}
                autoComplete={"username"}
                autoFocus
                required
              />
              <TextField
                name={"password"}
                control={control}
                label={"Password"}
                type={"password"}
                autoComplete={"current-password"}
                required
              />
              <SubmitButton text={"Sign In"} isLoading={isLoggingIn} />

              <Footer sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
    loading: state.apiCallsInProgress > 0,
    link: state.link,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loginUser: bindActionCreators(userActions.loginUser, dispatch),
      loadUserInfo: bindActionCreators(userActions.loadUserInfo, dispatch),
      setLink: bindActionCreators(linkActions.setLink, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Signin);
