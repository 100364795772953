import { useEffect } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as uiActions from "../../store/actions/uiActions";
import { Grid, Typography } from "@mui/material";
const Index = ({ user, actions }) => {
  const Item = useOutletContext();
  useEffect(() => {
    actions.setTitle("HOME");
    actions.setActiveNav("HOME");
    actions.setBreadcrumbs(null);
  }, []);
  return (
    <Grid container elevation={3}>
      <Grid item xs={12}>
        <Item>
          <Typography variant="h5" component="h2" mb={2}>
            WELCOME TO THE DOTr PORTAL!
          </Typography>

          <Typography variant="body1">
            This portal is a collection of MIS-developed DOTr web-based apps.
          </Typography>
          <Typography variant="body1">
            The following update(s) are currently being made to this portal:
          </Typography>
          <ul>
            <li>
              <Typography variant="body1">
                Viewing and Printing of Payslip
              </Typography>
            </li>
            <li>
              <Typography variant="body1">
                Viewing of Notice of Salary Adjustment (NOSA) and Notice of
                Salary Increment (NOSI)
              </Typography>
            </li>
          </ul>
        </Item>
      </Grid>
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setTitle: bindActionCreators(uiActions.setTitle, dispatch),
      setActiveNav: bindActionCreators(uiActions.setActiveNav, dispatch),
      setBreadcrumbs: bindActionCreators(uiActions.setBreadcrumbs, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
