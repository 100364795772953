import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { ThemeProvider } from "@mui/material/styles";
import { Box, CssBaseline } from "@mui/material";
import Theme from "../Template/Theme";
import DrawerHeader from "../Template/DrawerHeader";
import Item from "../Template/Item";
import TopBar from "./TopBar";
import SideBar from "./SideBar";
import TitleAndBreadcrumbs from "./TitleAndBreadcrumbs";
import Footer from "./Footer";
import * as userActions from "../../store/actions/userActions";
import * as linkActions from "../../store/actions/linkActions";
import { drawerWidth } from "../../static";

const Base = ({ links, actions }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = React.useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };
  useEffect(() => {
    actions.loadUserInfo("current").catch((e) => {
      actions.setLink(location.pathname);
      navigate("/signin");
    });
  }, [0]);
  return (
    <ThemeProvider theme={Theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <TopBar open={open} handleDrawer={handleDrawer} />
        <SideBar
          links={links}
          drawerWidth={drawerWidth}
          open={open}
          handleDrawer={handleDrawer}
        />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            p: 3,
          }}
        >
          <DrawerHeader />
          <TitleAndBreadcrumbs />

          <Outlet context={Item} />
          <Footer />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
    loading: state.apiCallsInProgress > 0,
    ui: state.ui,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadUserInfo: bindActionCreators(userActions.loadUserInfo, dispatch),
      setLink: bindActionCreators(linkActions.setLink, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Base);
