import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Base from "./components/Base/Base";
import Signin from "./routes/Signin/Signin";
import Index from "./routes/Index/Index";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import Payslip from "./routes/Payslip/Payslip";
import {
  faHouseChimney,
  faPesoSign,
  faFileContract,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import Notice from "./routes/Notice/Notice";
import Timekeeping from "./routes/Timekeeping/Index";
import Qrcodec from "./routes/Qrcodec/Qrcodec";
const store = configureStore();
const links = [
  {
    title: "HOME",
    url: "/",
    icon: faHouseChimney,
  },
  {
    title: "PAYSLIP",
    url: "/payslip",
    icon: faPesoSign,
  },
  {
    title: "NOTICE",
    url: "/notice",
    icon: faFileContract,
  },
  {
    title: "TIMEKEEPING",
    url: "/timekeeping",
    icon: faClock,
  },
  {
    title: "QRCODE",
    url: "/Qrcodec",
    icon: faFileContract,
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Base links={links} />,
    children: [
      {
        path: "/",
        element: <Index />,
      },
      {
        path: "/payslip",
        element: <Payslip />,
      },
      {
        path: "/notice",
        element: <Notice />,
      },
      {
        path: "/timekeeping",
        element: <Timekeeping />,
      },
      {
        path: "/qrcodec",
        element: <Qrcodec />,
      },
    ],
  },
  {
    path: "/signin",
    element: <Signin />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
