import React, { useState, useEffect } from "react";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { useReactToPrint } from "react-to-print";
// import DetailsPerCategory from "./DetailsPerCategory";
import { backend_prefix } from "../../static";
import CurrencyText from "../../components/Element/CurrencyText";
import QRCode from 'qrcode.react';
import { text } from "@fortawesome/fontawesome-svg-core";

const QrcodecDetails = ({ period, employee }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentDate, setCurrentDate] = useState(''); 
  const componentRef = React.useRef(null);
  
  useEffect(() => {
    var date = new Date().getDate(); //Current Date
    var month = new Date().getMonth() + 1; //Current Month
    var year = new Date().getFullYear(); //Current Year

    setCurrentDate(
      date + '/' + month + '/' + year 
    );
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  // const inputData = `${employee.first_name.toUpperCase()} ${employee.middle_name.charAt(0).toUpperCase()}. ${employee.last_name.toUpperCase()} ${employee.name_extension.toUpperCase()}\n${employee.employee_number}`;
return (
    <>
      {/* <Divider style={{ pt: 5, pb: 5, borderColor: "black" }} /> */}
      <div ref={componentRef} style={{ textAlign: 'center' }}>
<br />
<br />                    <Typography component="h3" variant="h5">
                      <b>QRCODE: </b>
                      {` ${employee.first_name.toUpperCase()} ${employee.middle_name.charAt(0).toUpperCase()}. ${employee.last_name.toUpperCase()}`}
                   <hr/>
                    </Typography>
<br />
<br />

        <Typography>
          <QRCode value={`${employee.first_name} ${employee.middle_name.charAt(0)}. ${employee.last_name},${employee.employee_number},Department of Transportation`} size={254} />
        </Typography>
        {/* End of centered content */}
      </div>
      <Grid container justifyContent="center">
      <Button
        variant="contained"
        onClick={handlePrint}
      >
        Print/Download
      </Button>
      </Grid>
      <Divider style={{ margin: 10 }} />
      <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
          
          </Grid>
      </Grid>
    </>
  );
};

export default QrcodecDetails;
