import React from "react";
import { connect } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { Box, Breadcrumbs, Grid, Link, Typography } from "@mui/material";

const TitleAndBreadcrumbs = ({ ui }) => {
  return (
    <Grid container spacing={2} sx={{ pb: 3 }}>
      <Grid item xs={8}>
        <Typography variant="h5" component="h1">
          {ui.title}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {ui.breadcrumbs && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
            key="Box"
          >
            <Breadcrumbs maxItems={3} aria-label="breadcrumb" key="breadcrumbs">
              {ui.breadcrumbs.map((value, index, array) => {
                return array.length - 1 === index ? (
                  <Typography color="text.primary" key={"breadcrumbs" + index}>
                    {value.title}
                  </Typography>
                ) : (
                  <Link
                    component={RouterLink}
                    underline="hover"
                    color="inherit"
                    to={value.link}
                    key={"breadcrumbs" + index}
                  >
                    {value.title}
                  </Link>
                );
              })}
            </Breadcrumbs>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    ui: state.ui,
  };
}

export default connect(mapStateToProps)(TitleAndBreadcrumbs);
