import React from "react";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Button, Divider, Grid, Typography } from "@mui/material";
import Loading from "../../components/Element/Loading";
import LoadNoticeForm from "./LoadNoticeForm";
import * as uiActions from "../../store/actions/uiActions";
const Notice = ({ user, actions }) => {
  const Item = useOutletContext();
  const [isNoticeWithContentsLoading, setIsNoticeWithContentsLoading] =
    useState(false);
  const [noticeWithContents, setNoticeWithContents] = useState(null);
  const [activeTab, setActiveTab] = useState(null);
  useEffect(() => {
    actions.setTitle("NOTICE");
    actions.setActiveNav("NOTICE");
    actions.setBreadcrumbs([{ link: "/", title: "HOME" }, { title: "NOTICE" }]);
  }, []);
  return (
    <Grid container spacing={2} elevation={3}>
      <Grid item xs={12} md={4}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Item>
              <LoadNoticeForm
                setNoticeWithContents={setNoticeWithContents}
                setIsNoticeWithContentsLoading={setIsNoticeWithContentsLoading}
                setActiveTab={setActiveTab}
              />
            </Item>
          </Grid>
        </Grid>
      </Grid>
      {activeTab === "NoticeWithContents" &&
        (noticeWithContents?.notice || isNoticeWithContentsLoading) && (
          <>
            <Grid item xs={12} md={8}>
              <Item>
                {isNoticeWithContentsLoading ? (
                  <Loading />
                ) : (
                  <>
                    <Typography component="h3" variant="h5">
                      Notice: {noticeWithContents.notice.title}
                    </Typography>

                    <Divider
                      style={{ pt: 5, pb: 5, mb: 5, borderColor: "black" }}
                    />

                    {noticeWithContents?.notice_content?.map((content) => {
                      return (
                        <>
                          <Button
                            key={noticeWithContents.notice.id}
                            component={"a"}
                            variant="contained"
                            href={content.link}
                            target={"_blank"}
                            download={`${noticeWithContents.notice.title}.pdf`}
                            sx={{ mt: 3 }}
                          >
                            Print/Download
                          </Button>
                          <iframe
                            src="http://localhost:8000/prot1_backend/api/payroll/1/3433/print"
                            width="640"
                            height="480"
                            allow="autoplay"
                          ></iframe>
                        </>
                      );
                    })}
                  </>
                )}
              </Item>
            </Grid>
          </>
        )}
    </Grid>
  );
};
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: {
      setTitle: bindActionCreators(uiActions.setTitle, dispatch),
      setActiveNav: bindActionCreators(uiActions.setActiveNav, dispatch),
      setBreadcrumbs: bindActionCreators(uiActions.setBreadcrumbs, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notice);
