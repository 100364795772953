export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";

export const SET_TITLE = "SET_TITLE";
export const SET_ACTIVE_NAV = "SET_ACTIVE_NAV";
export const SET_BREADCRUMBS = "SET_BREADCRUMBS";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOAD_USER_INFO = "LOAD_USER_INFO";
export const LOAD_USER_INFO_SUCCESS = "LOAD_USER_INFO_SUCCESS";

export const SET_USER_ERROR = "SET_USER_ERROR";
export const SET_LINK = "SET_LINK";

export const LOAD_SELF_PAYROLL_PERIODS = "LOAD_SELF_PAYROLL_PERIODS";
