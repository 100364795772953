import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function uiReducers(state = initialState.ui, action) {
  const { data } = action;
  switch (action.type) {
    case types.SET_TITLE:
      return {
        ...state,
        title: data,
      };

    case types.SET_ACTIVE_NAV:
      return {
        ...state,
        active_nav: data,
      };
    case types.SET_BREADCRUMBS:
      return {
        ...state,
        breadcrumbs: data,
      };

    default:
      return state;
  }
}
