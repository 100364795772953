import React from "react";
import { Typography } from "@mui/material";
import CurrencyText from "../../components/Element/CurrencyText";

const DetailsPerCategory = ({ details, filter }) => {
  return (
    <>
      {details &&
        details.map((detail) => {
          return (
            detail.element_type === filter &&
            detail.employee_amount !== 0 && (
              <Typography component="h4" variant="body1">
                {detail.element_title} :{" "}
                <CurrencyText value={detail.employee_amount} />
              </Typography>
            )
          );
        })}
    </>
  );
};

export default DetailsPerCategory;
