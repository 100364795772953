import * as types from "./actionTypes";

export const setTitle = (data) => {
  return { type: types.SET_TITLE, data };
};
export const setActiveNav = (data) => {
  return { type: types.SET_ACTIVE_NAV, data };
};
export const setBreadcrumbs = (data) => {
  return { type: types.SET_BREADCRUMBS, data };
};
